<template>
  <div class="contact-me">
    <h2>{{ contactHeader }}</h2>
    <div class="contact-info">
      <div class="contact-me-list">
        <i class="fa-solid fa-envelope"></i>
        <p>{{ email }}</p>
      </div>
      <div class="contact-me-list">
        <i class="fa-solid fa-mobile-screen"></i>
        <p>{{ phone }}</p>
      </div>
      <div class="contact-me-list">
        <i class="fa-solid fa-map-location-dot"></i>
        <p>{{ address }}</p>
      </div>
      <div class="contact-me-list">
        <i class="fa-brands fa-slack"></i>
        <p>{{ slack }}</p>
      </div>
    </div>
    <div class="social-icons">
      <a href="https://www.facebook.com/mikic.tr" target="_blank">
        <i class="fa-brands fa-square-facebook"></i>
      </a>
      <a href="https://www.instagram.com/mikic_b" target="_blank">
        <i class="fa-brands fa-square-instagram"></i>
      </a>
      <a href="https://www.linkedin.com/in/benjamin-mikic-1543531b9/" target="_blank">
        <i class="fa-brands fa-linkedin"></i>
      </a>
      <a href="https://gitlab.com/MikicB" target="_blank">
        <i class="fa-brands fa-square-gitlab"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contactHeader: "Contact",
      email: "mikicb92@outlook.com",
      phone: "+38761997311",
      address: "Barakovac bb, 88000 Mostar",
      slack: "MikicB"
    }
  }
}
</script>

<style scoped lang="scss">
.contact-me {
  // background: rgb(5, 8, 8);
  color: var(--light);
  position: relative;
  z-index: 99;
  display: flex;
  flex-direction: column;
  // width: 25%;
  // padding: 3rem;
  font-weight: 400;

  @media (max-width: 1400px) {
    padding: 1rem;
  }

  @media (max-width: 1100px) {
    width: 100%;
    padding: 2rem 1rem 0rem 1rem;
  }

  p {
    color: var(--light);
    padding: 0;
    letter-spacing: 0;
  }

  .contact-info {
    padding: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 280px) {
      padding: 0;
      font-size: 10pt;
    }

    .contact-me-list {
      display: flex;
      width: 100%;
      padding-top: 2rem;
      align-items: center;

      @media (max-width: 1400px) {
        padding-top: 1rem;
      }

      i {
        font-size: x-large;
        color: var(--light);
      }

      p {
        padding-left: 1rem;
        color: var(--light);
      }
    }
  }

  .social-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 3rem 0 0 0;

    @media (max-width: 1400px) {
    padding: 1.5rem;
  }

    @media (max-width: 1100px) {
      padding: 1rem 0 0 0;
    }

    p {
      font-size: medium;
    }

    a {
      color: grey;

      i {
        transition: transform 0.5s ease-out;
        font-size: 3rem;

        @media (max-width: 280px) {
          font-size: 1.5rem;
          margin: .5rem 0 0 0;
        }

        &:hover {
          transform: scale(.9);
          transition: transform 0.5s ease-in-out;
          color: #e0a13474;
        }
      }
    }
  }
}
</style>
