<template>
  <section class="about-section" id="about">
    <about-me></about-me>
  </section>
</template>

<script>
import AboutMe from '../layout/AboutMe.vue';
export default {
  components: {
    AboutMe
  },
}
</script>

<style lang="scss" scoped>
.about-section {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2rem;
  background: linear-gradient( #251C0B , rgb(8, 24, 8), rgb(4, 4, 4)), #7d540d;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  @media (max-width: 280px) {
    padding: 1.5rem;
  }
}
</style>
