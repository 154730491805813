<template>
    <land-section></land-section>
    <about-section></about-section>
    <portfolio-section></portfolio-section>
    <contact-section></contact-section>
</template>

<style lang="scss" scoped>
</style>

<script>
import LandSection from '../pages/LandSection.vue'
import AboutSection from '../pages/AboutSection.vue'
import PortfolioSection from '../pages/PortfolioSection.vue'
import ContactSection from '../pages/ContactSection.vue'

export default {
  components: {
    LandSection,
    AboutSection,
    PortfolioSection,
    ContactSection,
  },
}
</script>

