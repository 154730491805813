<template>
  <button type="submit">
    <a :href="href" :class="mode" v-motion :initial="{
      'box-shadow': '0 0 0px var(--nameColor)'
    }" :enter="{
  'box-shadow': '0 0 15px var(--nameColor)',
  transition: {
    duration: 1000,
    repeat: Infinity,
    repeatType: 'reverse',
  }
}">
      <slot></slot>
    </a>
  </button>
</template>

<script>
export default {
  props: ["mode", "href"],
};
</script>

<style lang="scss" scoped>
button {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16pt;
  max-width: 9rem;
  max-width: 3rem;
  text-decoration: none;
  background-color: #3a0061;
  cursor: pointer;
  color: white;
  background-size: 200%;
  transition: 0.5s ease-out;
  z-index: 9;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16pt;
    max-width: 9rem;
    max-width: 3rem;
    text-decoration: none;
    background-color: #3a0061;
    border: 2px solid #3a0061;
    cursor: pointer;
    color: white;
    background-size: 200%;
    transition: 0.2s ease-in-out;

    @media (max-width: 480px) {
      font-size: 14pt;
      width: 8rem;
      height: 3rem;
    }
  }
  &:hover {
        opacity: 0.4;
      }
}


a:hover,
a:active {
  background-position: left;
}

.flat {
  margin-top: 2rem;
  background-color: transparent;
  color: #c9b9d3;
  border: none;
  border-bottom: 2px solid black;
  border-left: 2px solid black;
  border-radius: 10px;
}

.outline {
  background-color: var(--nameColor);
  color: var(--light);
  border: none;
  min-width: 9rem;
  min-height: 3rem;
}

.outline-contactForm {
  background-color: var(--nameColor);
  color: var(--light);
  border: none;
  min-width: 7rem;
  min-height: 3rem;
}

.flat:hover,
.flat:active {
  background-color: var(--nameColor);
  color: var(--light);
  font-weight: 400;
}
</style>
