import { createApp } from "vue";
import { MotionPlugin } from "@vueuse/motion";
import App from "./App.vue";
import TheNavigation from "./components/layout/TheNavigation.vue";
import MainContent from "./components/layout/MainContent.vue";
import TheFooter from './components/layout/inner-layouts/TheFooter.vue'
import BaseButton from "./components/ui/BaseButton.vue";

const app = createApp(App);
app.component("the-navigation", TheNavigation);
app.component("main-content", MainContent);
app.component("base-button", BaseButton);
app.component("the-footer", TheFooter);

app.use(MotionPlugin);

app.mount("#app");