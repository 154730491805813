<template>
  <header :class="{ 'scrolled-nav': scrollNav }">
    <nav>
      <div class="logo">
        <img src="../../assets/images/logo.jpg" alt="logo of website">
      </div>
      <ul v-show="!mobile" class="navigation">
        <li class="link"><a href="#land">Home</a></li>
        <li class="link"><a href="#about">About</a></li>
        <li class="link"><a href="#portfolio">Portfolio</a></li>
        <li class="link"><a href="#contact">Contact</a></li>
      </ul>
      <div id="burger" :class="{ 'active': mobileNav }" @click="toggleMobileNav" v-show="mobile">
        <button type="button" class="burger-button" title="Menu">
          <span class="burger-bar burger-bar--1"></span>
          <span class="burger-bar burger-bar--2"></span>
          <span class="burger-bar burger-bar--3"></span>
        </button>
      </div>
      <transition name="mobile-nav">
        <ul v-show="mobileNav" class="dropdown-nav" @click="closeNav">
          <li class="link"><a href="#land">Home</a></li>
          <li class="link"><a href="#about">About</a></li>
          <li class="link"><a href="#portfolio">Portfolio</a></li>
          <li class="link"><a href="#contact">Contact</a></li>
        </ul>
      </transition>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'navigation',
  data() {
    return {
      scrollNav: false,
      mobile: false,
      mobileNav: false,
      windowWidth: null,
    };
  },
  created() {
    window.addEventListener('resize', this.checkscreen)
    this.checkscreen()
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  methods: {
    closeNav() {
      this.mobileNav = false
    },
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav
    },
    checkscreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 750) {
        this.mobile = true
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
    updateScroll() {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50) {
        this.scrollNav = true;
        return;
      }
      this.scrollNav = false;
    }
  },
}
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  transition: 0.5s ease all;
  color: #fff;
  top: 0px;
  height: 100px;
  z-index: 100;
  display: flex;
  min-width: 80%;
  left: 50%;
  transform: translate(-50%, 10%);
  background-color: none;

  @media (max-width: 1100px) {
    position: fixed;
    transition: 0.5s ease all;
    top: 0;
    color: #fff;
    height: 80px;
    z-index: 100;
    display: flex;
    min-width: 100%;
    left: 0;
    transform: translate(0, 0);
    border-radius: 0px;
  }

  nav {
    display: flex;
    transition: 0.5s ease all;
    color: #FFFFFF;
    width: 100%;
    justify-content: space-between;

    @media (max-width: 1100px) {
      justify-content: space-around;
    }
    @media (max-width: 750px) {
      display: flex;
      justify-content: center;
  }

    ul,
    .link a {
      font-weight: 500;
      font-size: 1rem;
      color: white;
      list-style: none;
      text-decoration: none;
      text-transform: uppercase;
    }

    li {
      margin-left: 2rem;

      &:hover {
        color: #e0a134;
        border-color: #e0a134;
      }
    }

    .logo {
      display: flex;
      align-items: center;

      img {
        width: 4rem;
        height: 3.7rem;
        transition: 0.5s ease all;
        padding: .3rem;
      }
    }

    .link {
      transition: .5s ease all;
      padding-bottom: 4px;
      border-bottom: 1px solid transparent;
    }

    .dropdown-nav {
      display: flex;
      flex-direction: column;
      position: fixed;
      width: 100vw;
      min-height: 100vh;
      background: linear-gradient(89deg, black, transparent), rgba(0, 0, 0, 0.9);
      justify-content: center;
      padding: 2rem;
      z-index: -1;
      text-align: end;

      li {
        margin-left: 0;
        padding: 2vh 0;
        font-size: 9vw;

        .link,
        a {
          text-transform: capitalize;
          color: whitesmoke;
          font-size: 2.5rem;
        }
      }
    }

    .mobile-nav-enter-active {
      transition: 1s ease all;
    }

    .mobile-nav-leave-active {
      transition: 1s ease all;
    }

    .mobile-nav-enter-from,
    .mobile-nav-leave-to {
      transform: translateX(-100%);
    }

    .mobile-nav-enter-to {
      transform: translateX(0);
    }

    .navigation {
      display: flex;
      align-items: center;

      li {
        font-size: larger;

        .link,
        a {
          color: whitesmoke;
          display: flex;
          width: 100%;
        }
      }
    }

    button {
      cursor: pointer;
    }

    button:focus {
      outline: 0;
    }

    .burger-button {
      position: absolute;
      display: flex;
      height: 1rem;
      width: 3rem;
      border: 0;
      border-radius: 0;
      background-color: transparent;
      pointer-events: all;
      right: 1rem;
      z-index: 99;
      top: 2rem;
      transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .burger-bar {
      background-color: #FFF;
      position: absolute;
      top: 50%;
      right: 6px;
      left: 6px;
      height: 3px;
      width: auto;
      margin-top: -1px;
      transition: transform .6s cubic-bezier(.165, .84, .44, 1), opacity .3s cubic-bezier(.165, .84, .44, 1), background-color .6s cubic-bezier(.165, .84, .44, 1);
    }

    .burger-bar--1 {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px);
      top: 40%;
    }

    .burger-bar--2 {
      transform-origin: 100% 50%;
      transform: scaleX(1);
    }

    .burger-button:hover .burger-bar--2 {
      transform: scaleX(1);
    }

    .no-touchevents .burger-bar--2:hover {
      transform: scaleX(1);
    }

    .burger-bar--3 {
      transform: translateY(6px);
      top: 60%;
    }

    #burger.active .burger-button {
      transform: rotate(-180deg);
    }

    #burger.active .burger-bar {
      background-color: var(--light);
    }

    #burger.active .burger-bar--1 {
      transform: rotate(45deg);
      top: 50%;
    }

    #burger.active .burger-bar--2 {
      opacity: 0;
    }

    #burger.active .burger-bar--3 {
      transform: rotate(-45deg);
      top: 50%;
    }
  }
}

.scrolled-nav {
  -webkit-backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border: 1px solid rgba(67, 73, 71, 0.4);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  transition: 0.5s ease all;
  color: #fff;
  top: 0px;
  height: 80px;
  z-index: 100;
  display: flex;
  min-width: 65%;
  border-radius: 100px;
  left: 50%;
  transform: translate(-50%, 50%);
  @media (max-width: 750px) {
  width: 100%;
  border-radius: 0 10px 0 10px;
  border: none;
  position: fixed;
  transform: none;
  left: 0;
  transition: 0.7s ease all;  
  }

  nav {
    justify-content: space-around;
    @media (max-width: 750px) {
      justify-content: center;
  }
  }

  @media (max-width: 1100px) {
    min-width: 80%;

    #burger .burger-button {
      transition: .5s ease-in-out all;
    }
  }
}
</style>
