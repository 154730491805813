<template>
  <form @submit.prevent="sendEmail">
    <div class="form-control">
      <input type="text" name="name" id="firstname" :class="{ invalid: !firstname.isValid }" class="input-field"
        placeholder="Name" v-model="firstname.val" @blur="clearValidity('firstname')" />
      <label for="firstname" class="label-field">Name</label>
      <p v-if="!firstname.isValid">Tell me your name</p>
    </div>
    <div class="form-control">
      <input type="email" name="email" id="email" :class="{ invalid: !email.isValid }" class="input-field"
        placeholder="Email" v-model="email.val" @blur="clearValidity('email')" />
      <label for="email" class="label-field">Email</label>
      <p v-if="!email.isValid">Enter your email address</p>
    </div>
    <div class="form-control">
      <textarea name="message" id="message" row="100" :class="{ invalid: !message.isValid }" class="input-field"
        v-model="message.val" placeholder="Message" @blur="clearValidity('message')">Write me</textarea>
      <label for="message" class="label-field">Message</label>
      <p v-if="!message.isValid">Be free to send me message</p>
    </div>
    <base-button mode="outline-contactForm" class="success-button" v-if="!isSent">Send</base-button>
    <p class="success-msg" v-if="isSent">Message sent!</p>
  </form>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  data() {
    return {
      firstname: {
        val: '',
        isValid: true
      },
      email: {
        val: '',
        isValid: true
      },
      message: {
        val: '',
        isValid: true
      },
      formIsValid: true,
      isSent: false
    }
  },
  methods: {
    sendEmail(e) {
      try {
        console.log(this.e);
        this.validateForm();
        if (!this.formIsValid) {
          return;
        }
        emailjs.sendForm('service_5rv5kmq', 'template_9o4s21z', e.target,
          'uS7n73_dDAUPrmbPs', {
          name: this.firstname.val,
          email: this.email.val,
          message: this.message.val
        })
        this.isSent = true
      } catch (error) {
        console.log({ error })
      }
      // Reset form field
      this.firstname.val = ''
      this.email.val = ''
      this.message.val = ''
    },
    clearValidity(input) {
      this[input].isValid = true
    },
    validateForm() {
      this.formIsValid = true;
      if (this.firstname.val == '') {
        this.firstname.isValid = false;
        this.formIsValid = false
        this.clearValidity()
      }
      if (this.email.val == '') {
        this.email.isValid = false;
        this.formIsValid = false
      }
      if (this.message.val == '') {
        this.message.isValid = false;
        this.formIsValid = false
      }
    },
  }
}
</script>

<style scoped lang="scss">
form {
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: center;
  padding: 0rem;
  align-items: center;

  @media (max-width: 1100px) {
    width: 100%;
  }
}

.form-control {
  position: relative;
  padding: 15px 0 0;
  margin-top: 1.5rem;
  width: 80%;

  @media (max-width: 1400px) {
    padding: .7rem 0 0
  }

  @media (max-width: 1100px) {
    width: 90%;
    margin-top: 1rem;
  }
}

.form-control .invalid {
  border-color: var(--nameColor);
}

.form-control p {
  color: var(--nameColor);
  font-size: small;
  padding: .5rem 0 0 0;
}

.input-field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  font-size: 11pt;
  color: white;
  padding: 7px 0;
  background-color: transparent;


  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown~.label-field {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;

    @media (max-width: 1400px) {
      font-size: 1rem;
    }
  }
}

.label-field {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: gray;
}
input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--light);
}
input:autofill {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--light);
}


.input-field:focus {
  ~.label-field {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #e0a13498;
    font-weight: 500;
  }

  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #e0a13498, rgb(2, 73, 60));
  border-image-slice: 1;
}

/* reset input */
.input-field {
  &:required,
  &:invalid {
    box-shadow: none;
  }
}

.success-button {
  margin-top: 2rem;

  @media (max-width: 1100px) {
    margin: 2rem;
  }
}

.success-msg {
  color: rgb(17, 142, 153);
  ;
  font-weight: 600;
  margin-top: 2rem;

  @media (max-width: 1100px) {
    margin: 2rem;
  }
}
</style>
