<template>
  <section class="banner-section" id="land" v-motion-fade :delay="200" :initial="{ opacity: 0.2 }">
    <div class="content-area">
      <div class="content">
        <h1 class="animate seven">
          <span>H</span><span>e</span><span>l</span><span>l</span><span>o</span><span
            class="dash-color">,</span><br /><span>I</span>&nbsp;<span>a</span><span>m</span>&nbsp;<span><span class="redColor">B</span><span class="redColor">e</span><span class="redColor">n</span><span
              class="redColor">j</span><span class="redColor">a</span><span class="redColor">m</span><span
              class="redColor">i</span><span class="redColor">n</span>
          </span>
        </h1>
        <h2 v-motion :initial="{
          opacity: 0,
          y: 100,
        }" :enter="{
  opacity: 1,
  y: 0,
  transition: {
    type: 'spring',
    stiffness: 90,
    delay: 1300,
  },
}">
          a
          self<span class="dash-color">-</span>taught
          frontend developer<span class="dash-color exp-mark">!</span>
        </h2>
      </div>
      <base-button mode="outline" href="#portfolio">Projects</base-button>
      <div class="side-text">
        <p>mikicB92@outlook.com</p>
        <div class="container">
          <div class="field">
            <div class="mouse"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.dash-color {
  color: var(--nameColor);
}
.exp-mark {
  font-weight: 700;
}

.banner-section {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background: linear-gradient(rgba(8, 17, 14, 0.3), rgba(4, 19, 14, .3),  rgba(13, 25, 21, .3)), url(../../assets/images/camo.jpg);
  background-attachment: fixed;

  @media (max-width: 280px) {
    padding: 1rem;
  }
  @media (max-width: 500px) {
    padding: 2rem;
  }

  .content-area {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content {
      // text-align: center;

      h1 {
        text-align: left;
        font-size: 6rem;
        font-weight: 600;
        color: var(--light);
        margin-bottom: 1rem;
        text-shadow: -5px -5px rgba(0, 0, 0, 0.395);
        .redColor {
          font-size: 7rem;
          color: var(--nameColor);
        }
      }

      h2 {
        text-align: center;
        font-size: 1.4rem;
        font-weight: 400;
        color: var(--light);
        margin-bottom: 4rem;
        text-shadow: -5px -5px rgb(0 0 0 / 64%);
        code {
          font-family: initial;
          font-weight: 600;
          transform: translateY(20deg);
          letter-spacing: 0.1rem;

          @media (max-width: 280px) {
            letter-spacing: 0.1rem;
          }
        }
      }

      a {
        width: 9rem;
        height: 4rem;
        box-shadow: rgb(0 0 0 / 32%) -10px -10px 0px 0px;

        &:hover {
          box-shadow: none;
        }
      }

      @media (max-width: 1400px) {
        h1 {
          font-size: 4rem;
          padding-top: 7rem;

          .redColor {
            font-size: 5rem;
          }
        }

        h2 {
          font-size: 1.2rem;
        }
      }

      @media (max-width: 1100px) {
        h1 {
          font-size: 3rem;
          padding-top: 0.5rem;


          .redColor {
            font-size: 3.7rem;
          }
        }

        h2 {
          font-size: 1.2rem;
          margin-bottom: 4rem;
          text-align: end;
          opacity: 0.7;
        }
      }

      @media (max-width: 480px) {
        h1 {
          margin-bottom: 0rem;
          padding-bottom: .5rem;
          padding-top: 0;
          font-size: 3rem;

          .redColor {
            font-size: 3.5rem;
          }
        }
        h2 {
          font-size: .9rem;
        }
      }

      @media (max-width: 280px) {
        h1 {
          font-size: 2.3rem;
          letter-spacing: 0px;
          text-align: left;
          margin-bottom: 0rem;

          .redColor {
            font-size: 3rem;
          }
        }

        h2 {
          font-size: 0.9rem;
          color: var(--light);
          margin-bottom: 4rem;
          letter-spacing: 0.2rem;
        }
      }
    }

    .side-text {
      color: var(--light);
      display: flex;
      width: 100%;
      justify-content: space-between;
      position: absolute;
      bottom: 0;
      padding: 0 2rem 2rem;
      @media (max-width: 1400px) {
          padding: 1rem;
      }

      @media (max-width: 480px) {
        padding: 0 0.5rem 4rem 0.5rem;

      }

      @media (max-width: 280px) {
        padding: 0 0.5rem 4rem 0.5rem;
      }

      .container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-self: flex-end;

        @media (max-width: 1400px) {
          


      }

      @media (max-width: 1100px) {
        // align-self: flex-end;

      }

      @media (max-width: 480px) {
        padding: 0 0.5rem 0rem 0.5rem;
      }

      @media (max-width: 280px) {
        // align-self: flex-end;

      }
        
        .field {

          .mouse {
            width: 50px;
            height: 90px;
            border: 3px solid #333;
            border-radius: 60px;
            position: relative;

            &::before {
              content: "";
              width: 12px;
              height: 12px;
              position: absolute;
              top: 10px;
              left: 50%;
              transform: translateX(-50%);
              background: var(--nameColor);
              border-radius: 50%;
              opacity: 1;
              -webkit-animation: wheel 2s infinite;
              animation: wheel 2s infinite;
            }
          }

          @keyframes wheel {
            to {
              opacity: 0;
              top: 60px;
            }
          }

          @-webkit-keyframes wheel {
            to {
              opacity: 0;
              top: 60px;
            }
          }
        }
      }

      p {
        writing-mode: vertical-lr;
        color: #e0a1344a;
        letter-spacing: 1px;
        cursor: pointer;
        transition: 0.5s all ease-out;
        &:hover {
          color: #e0a134;
          transition: 0.5s all ease-out;
        }

      }
    }

    .animate span {
      display: inline-block;
    }

    .seven span {
      opacity: 0;
      transform: translate(-150px, 0) scale(0.3);
      -webkit-animation: leftRight 0.8s forwards;
      animation: leftRight 0.8s forwards;
    }

    @keyframes leftRight {
      40% {
        transform: translate(50px, 0) scale(0.7);
        opacity: 1;
        color: #8fcaec;
      }

      60% {
        color: #c3d1f3;
      }

      80% {
        transform: translate(0) scale(2);
        opacity: 0;
      }

      100% {
        transform: translate(0) scale(1);
        opacity: 1;
      }
    }

    .animate span:nth-of-type(2) {
      -webkit-animation-delay: 0.5s;
      animation-delay: 0.05s;
    }

    .animate span:nth-of-type(3) {
      -webkit-animation-delay: 0.1s;
      animation-delay: 0.1s;
    }

    .animate span:nth-of-type(4) {
      -webkit-animation-delay: 0.15s;
      animation-delay: 0.15s;
    }

    .animate span:nth-of-type(5) {
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
    }

    .animate span:nth-of-type(6) {
      -webkit-animation-delay: 0.25s;
      animation-delay: 0.25s;
    }

    .animate span:nth-of-type(7) {
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
    }

    .animate span:nth-of-type(8) {
      -webkit-animation-delay: 0.35s;
      animation-delay: 0.35s;
    }

    .animate span:nth-of-type(9) {
      -webkit-animation-delay: 0.4s;
      animation-delay: 0.4s;
    }

    .animate span:nth-of-type(10) {
      -webkit-animation-delay: 0.45s;
      animation-delay: 0.45s;
    }

    .animate span:nth-of-type(11) {
      -webkit-animation-delay: 0.5s;
      animation-delay: 0.5s;
    }

    .animate span:nth-of-type(12) {
      -webkit-animation-delay: 0.55s;
      animation-delay: 0.55s;
    }

    .animate span:nth-of-type(13) {
      -webkit-animation-delay: 0.6s;
      animation-delay: 0.6s;
    }

    .animate span:nth-of-type(14) {
      -webkit-animation-delay: 0.65s;
      animation-delay: 0.65s;
    }

    .animate span:nth-of-type(15) {
      -webkit-animation-delay: 0.7s;
      animation-delay: 0.7s;
    }

    .animate span:nth-of-type(16) {
      -webkit-animation-delay: 0.75s;
      animation-delay: 0.75s;
    }

    .animate span:nth-of-type(17) {
      -webkit-animation-delay: 0.8s;
      animation-delay: 0.8s;
    }

    .animate span:nth-of-type(18) {
      -webkit-animation-delay: 0.85s;
      animation-delay: 0.85s;
    }
  }

}</style>
