<template>
  <the-navigation></the-navigation>
  <main-content></main-content>
  <the-footer></the-footer>
</template>

<style lang="scss">
:root {
  --primary: #4ade80;
  --grey: #64748b;
  --dark: rgb(6, 37, 66);
  --dark-alt: #334155;
  --light: #f1f5f9;
  --sidebar-width: 15rem;
  --nameColor: rgb(111, 11, 11);
  --background: url("./assets/images/background.jpg");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Chivo Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}
body {
  width: 100%;
  height: 100vh;
  // background: var(--background);
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
}
</style>
