<template>
  <section class="contact-section" id="contact">
    <div class="get-in-touch">
      <h2>{{ contactHeader }}</h2>
      <p class="get-in-touch-para">
        {{ getInTouchPara }}
      </p>
      <div class="get-in-touch-field">
        <contact-me></contact-me>
        <contact-form></contact-form>
      </div>
    </div>
  </section>
</template>
<script>
import ContactForm from "../layout/ContactForm.vue";
import ContactMe from "../layout/inner-layouts/ContactMe.vue"

export default {
  data() {
    return {
      contactHeader: "Get In Touch",
      getInTouchPara: "I'm interested in freelance opportunities - especiallty ambitious or large projects. If you have other requests or questions, please use the form."
    }
  },
  components: {
    ContactForm,
    ContactMe
  },
};
</script>

<style lang="scss" scoped>
.contact-section {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: #080808;
  background-size: cover;
  min-height: 100vh;
  background-origin: padding-box;
  justify-content: space-evenly;  

  @media (max-width: 1400px) {
    padding: 1rem;
  }

  @media (max-width: 280px) {
    padding: 1rem;
  }

  @media (max-width: 280px) {
    padding: 1rem;
  }
  h2 {
    font-size: 3rem;
    padding: 0rem 0rem 0rem 6rem;
    color: var(--light);

    @media (max-width: 1400px) {
      padding: 7rem 0rem 0rem 6rem;
    }

    @media (max-width: 1100px) {
      font-size: 2rem;
      padding: 5rem 0rem 2rem 0rem;
      letter-spacing: 0.1rem;
    }
  }

  .get-in-touch {
    // display: flex;
    // justify-content: center;
    // flex-direction: column;

    @media (max-width: 1100px) {
      padding: 0rem;
    }

    .get-in-touch-para {
      color: var(--light);
      font-size: 14pt;
      text-align: center;
      padding: 1rem;
      


      @media (max-width: 1400px) {
        font-size: 11pt;
      }

      @media (max-width: 768px) {
        font-size: 11pt
      }

      @media (max-width: 480px) {
        font-size: 11pt;
      }

      @media (max-width: 280px) {
        font-size: 11pt;
      }
    }

    .get-in-touch-field {
      display: flex;
      justify-content: space-evenly;
      padding: 2rem 0 1rem 0rem;

      @media (max-width: 1400px) {
        padding: 1rem 0rem 1rem 0rem;
      }

      @media (max-width: 1100px) {
        padding: 1rem 0rem 0rem 0rem;
        flex-direction: column-reverse;
      }
    }
  }
}
</style>
