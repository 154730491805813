<template>
  <div class="my-skills">
    <div class="fake-text">
      Skills
    </div>
    <h2>Skills <span class="dash-color">&</span> Experiences</h2>
    <p>
      {{ skillFirstPara }}
    </p>
    <p>
      {{ skillSecPara }}
    </p>
  </div>
  <div class="circle-bar-leyout">
    <div class="circle-bars">
      <circle-progress :is-bg-shadow="true" :bg-shadow="{
        inset: true,
        vertical: 3,
        horizontal: 2,
        blur: 4,
        opacity: 0.4,
        color: '#000000',
      }" :transition="2000" :percent="85" :viewport="true" :show-percent="true" :is-gradient="true"
        empty-color="#FFFFFF" :border-width="12" :border-bg-width="3" :gradient="{
          angle: 180,
          startColor: 'var(--nameColor)',
          stopColor: '#1D7353',
        }" />
      <h3>HTML</h3>
    </div>
    <div class="circle-bars">
      <circle-progress :is-bg-shadow="true" :bg-shadow="{
        inset: true,
        vertical: -6,
        horizontal: 5,
        blur: 4,
        opacity: 0.4,
        color: '#000000',
      }" :transition="2000" :percent="70" :viewport="true" :show-percent="true" :is-gradient="true"
        empty-color="#ffffff" :border-width="12" :border-bg-width="3" :gradient="{
          angle: 180,
          startColor: 'var(--nameColor)',
          stopColor: '#1D7353',
        }" />
      <h3>CSS</h3>
    </div>
    <div class="circle-bars">
      <circle-progress :is-bg-shadow="true" :bg-shadow="{
        inset: true,
        vertical: 2,
        horizontal: 5,
        blur: 4,
        opacity: 0.4,
        color: '#000000',
      }" :transition="2000" :percent="65" :viewport="true" :show-percent="true" :is-gradient="true"
        empty-color="#FFFFFF" :border-width="12" :border-bg-width="3" :gradient="{
          angle: 180,
          startColor: 'var(--nameColor)',
          stopColor: '#1D7353',
        }" />
      <h3>JS</h3>
    </div>
  </div>
</template>
<script>
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
export default {
  data() {
    return {
      skillFirstPara: "I create responsive websites that are fast, easy to use, and build with best practic.",
      skillSecPara: "The main area of my expertise is front-end development, HTML, CSS, JS, building small and medium web apps, custom plugins, feature, animations, and coding interactive layouts"
    }
  },
  components: { CircleProgress },
};
</script>
<style scoped lang="scss">
.my-skills {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  justify-content: center;
  align-items: center;

  @media (max-width: 1100px) {
    padding: 0rem;
  }
  .fake-text {
    font-family: sans-serif;
    font-weight: 600;
    position: absolute;
    left: 0vw;
    z-index: -1;
    font-size: 25vw;
    color: rgba(255, 255, 255, 0.04);
    @media (max-width: 480px) {
       overflow-wrap: break-word;    
      top: 113vh;
     }
    }
  h2 {
    color: var(--light);
    padding-bottom: 2rem;
    font-size: 24pt;
    font-weight: 900;
    letter-spacing: 1px;

    .dash-color {
      color: var(--nameColor)
    }

    @media (max-width: 1100px) {
      font-size: 16pt;
    }
  }

  p {
    color: var(--light);
    text-align: center;
    font-size: 14pt;
    font-weight: 400;
    line-height: 1.5;


    @media (max-width: 1400px) {
        font-size: 11pt;
      }

      @media (max-width: 768px) {
        font-size: 11pt
      }

      @media (max-width: 480px) {
        font-size: 11pt;
      }

      @media (max-width: 280px) {
        font-size: 11pt;
      }
  }
}

.circle-bar-leyout {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 1100px) {
    flex-direction: column;
  }

  .circle-bars {
    padding: 1rem;
    text-align: center;
    color: white;
    font-size: 24pt;
    align-self: center;
  }

  h3 {
    color: var(--light);
    font-size: 18pt;
    padding: 1rem;
  }
}
</style>
