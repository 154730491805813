<template>
    <div class="fake-text">
      About
    </div>
  <h2 class="animateHeader seven">
    <span>.</span><span>n</span><span>i</span><span>c</span><span>e</span><span>T</span><span>o</span><span>M</span><span>e</span><span>e</span><span>t</span><span>Y</span><span>o</span><span>u</span>
  </h2>
  <div class="about-me">
    <div class="left-about-me">
      <p class="about-me-first-text">
        I'm from Bosnia and Herzegovina, Travnik.<br />
        I have serious passion for UI effects, animation and creating dynamic
        user experiences.<br />
        Love for programming was born in army, where I was on watch, during a
        long and boring day's.
      </p>
      <br>
      <br>
      <p class="about-me-sec-text">
        Since beginning my journey as a <span>self-taught</span> developer
        nearly <span>4</span> years ago, I've done <span>remote</span> work for
        agencie and collaborated with <span>talented</span> people/friends to
        create <span>web sites</span> for learning, business and consumer use.
        I'm quietly confident, naturally curious, and perpetually working on
        <span>improving</span> my developer <span>skills</span>.
      </p>
    </div>
    <div class="right-about-me">
      <img src="../../../assets/images/soldier-avatar.jpg" class="avatar-img" alt="avatar" />
    </div>
  </div>
</template>

<style scoped lang="scss">

    .fake-text {
    font-family: sans-serif;
    font-weight: 600;
    position: absolute;
    left: 22vw;
    z-index: -1;
    font-size: 25vw;
    color: rgba(255, 255, 255, 0.04);
    }
h2 {
  font-size: 3rem;
  padding: 8rem 0rem 2rem 6rem;
  letter-spacing: 1px;
  color: var(--light);
  font-weight: 900;
  width: 100%;

  @media (max-width: 1400px) {
    padding: 6rem 0rem 0rem 6rem;
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 5.4rem 0 2rem 0;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
    padding: 4rem 0rem 3rem 0rem;
    letter-spacing: 2px;
  }

  @media (max-width: 280px) {
    font-size: 1.4rem;
    padding: 4rem 0rem 3rem 0rem;
    letter-spacing: 2.3px;
  }
}

.animateHeader span {
  display: inline-block;
}

.animateHeader span:nth-of-type(2) {
  animation-delay: .05s;
}

.animateHeader span:nth-of-type(3) {
  animation-delay: .1s;
}

.animateHeader span:nth-of-type(4) {
  animation-delay: .15s;
}

.animateHeader span:nth-of-type(5) {
  animation-delay: .2s;
}

.animateHeader span:nth-of-type(6) {
  animation-delay: .25s;
}

.animateHeader span:nth-of-type(7) {
  animation-delay: .3s;
}

.animateHeader span:nth-of-type(8) {
  animation-delay: .35s;
}

.animateHeader span:nth-of-type(9) {
  animation-delay: .4s;
}

.animateHeader span:nth-of-type(10) {
  animation-delay: .45s;
}

.animateHeader span:nth-of-type(11) {
  animation-delay: .5s;
}

.animateHeader span:nth-of-type(12) {
  animation-delay: .55s;
}

.animateHeader span:nth-of-type(13) {
  animation-delay: .6s;
}

.animateHeader span:nth-of-type(14) {
  animation-delay: .65s;
}

.seven span {
  animation: leftRight 10s ease-in-out infinite;
}

@keyframes leftRight {
  30% {
    color: #d8f4d5;
  }

  40% {
    color: #11611cd2;
  }

  60% {
    color: #08340ed2;
  }

  70% {
    color: #11611cd2;
  }

  90% {
    color: #d8f4d5;
  }
}

.about-me {
  display: flex;
  flex-direction: row;
  padding: 3rem;

  @media (max-width: 1400px) {
    padding: 0 2rem;
  }

  @media (max-width: 1100px) {
    flex-direction: column-reverse;
    padding: 0rem;
  }

  .left-about-me {
    flex-direction: column;
    display: flex;
    flex: 1 1 100%;
    padding: 3rem 0;
    align-self: center;
    
    @media (max-width: 1400px) {
      padding: 0  3rem 3rem 3rem;
    }
    @media (max-width: 1100px) {
      padding: 1.5rem 0;
    }
    .about-me-first-text, .about-me-sec-text {
      padding: 2rem 2rem 2rem 20rem;
      font-size: 14pt;
      font-weight: 400;
      line-height: 1.5;
      text-align: right;
      color: var(--light);

      @media (max-width: 1400px) {
        padding: 0rem;
        text-align: right;
        font-size: 11pt;
      }

      @media (max-width: 1100px) {
        padding: 0rem;
        line-height: 1.3;
        text-align: center;
      }

      @media (max-width: 768px) {
        font-size: 11pt
      }

      @media (max-width: 480px) {
        font-size: 11pt;
        
      }

      @media (max-width: 280px) {
        font-size: 11pt;
      }
    }
  }

  .right-about-me {
    display: flex;
    width: 100%;
    @media (max-width: 1400px) {
      justify-content: center;
      padding: 0 3rem 3rem 3rem
    }
    .avatar-img {
      min-width: 80%;
      min-height: auto;
      object-fit: contain;
      mix-blend-mode: hard-light;  
      aspect-ratio: 1/1;
      border-radius: 50%;

      @media (max-width: 1400px) {
        width: 90%;
      }

      @media (max-width: 1100px) {
        width: 50%;
      }
      @media (max-width: 480px) {
        width: 70%;
      }
    }
  }
}
</style>
