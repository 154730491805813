<template>
  <div class="progress-bar" ref="scrollAnimate">
    <div class="info">
      <label>{{ label }}</label>
      <label class="percentage">{{ count }}%</label>
    </div>
    <div class="background-bar">
      <transition appear @before-appear="beforeEnter" @after-appear="enter" v-if="isScrolled">
        <div class="tracker-bar"></div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      count: 0,
      isScrolled: false
    }
  },
  props: {
    percentage: Number,
    label: String,
    color: String,
  },
  created() {
    window.addEventListener('scroll', this.onElemenetScroll)
  },
  methods: {
    beforeEnter(el) {
      el.style.width = 0
    },
    enter(el) {
      el.style.width = `${this.percentage}%`
      el.style.background = this.color;
      el.style.transition = `width 1s linear`
      let interval = setInterval(() => {
        if (this.count < this.percentage) {
          this.count += 1
        } else
          clearInterval(interval)
      }, 15)
    },
    onElemenetScroll() {
      const box = this.$refs.scrollAnimate
      if (this.isInViewport(box)) {
        this.isScrolled = true
      }
    },
    isInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    }
  },
}
</script>

<style scoped lang="scss">
$greydark: #c0cac9;
$black: #333;
$screen: #eff3f4;
$pink: #ff298a;
$grey: #dedfe0;

.progress-bar {
  width: 100%;
  padding: 1rem;

  @media (max-width: 1100px) {
    width: 100%;
  }

  label {
    color: var(--light);
    padding-bottom: .5rem;
    font-weight: 600;
  }

  .info {
    font-size: 17px;
    justify-content: space-between;
    display: flex;
    color: grey;
    line-height: 1.5;

    .percentage {
      font-weight: bolder;
    }
  }

  .background-bar {
    background: #cccccc;
    width: 100%;
    height: .7rem;
    border-radius: 3px;
  }

  .tracker-bar {
    background: grey;
    height: .7rem;
    width: 0;
    transition: width 0.5s linear;
    border-radius: 3px;
  }
}

.vueColor {
  background: var(--vueColor);
}
</style>
