<template>
  <about-me-content></about-me-content>
  <my-skills></my-skills>
  <my-other-skills></my-other-skills>
</template>

<script>
import AboutMeContent from './inner-layouts/AboutMeContent.vue';
import MySkills from './inner-layouts/MySkills.vue';
import MyOtherSkills from './inner-layouts/MyOtherSkills.vue';

export default {
  components: {
    AboutMeContent,
    MySkills,
    MyOtherSkills
  }
}
</script>
