<template>
  <section class="portfolio-section" id="portfolio">
    <h2>Some projects I<span class="dash-color">'</span>ve built</h2>
    <portfolio-cards></portfolio-cards>
  </section>
</template>

<script>
import PortfolioCards from '../layout/inner-layouts/PortfolioCards.vue'
export default {
  components: {
    PortfolioCards
  }
}
</script>

<style lang="scss" scoped>
.portfolio-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #1D7353;
  // background: linear-gradient(rgba(0, 0, 0, 0.833), rgba(0, 0, 0, 0.7)), #0B1C26;
  background: linear-gradient(rgba(8, 17, 14, 0.3), rgba(4, 19, 14, .3),  rgba(13, 25, 21, .3)), url(../../assets/images/camo.jpg   );
  background-attachment: fixed;
  h2 {
    color: var(--light);
    padding: 9rem 0 1rem 0;
    font-size: 24pt;
    letter-spacing: 1px;
    font-weight: 900;

    .dash-color {
      color: var(--nameColor)
    }
    @media (max-width: 1400px) {
      padding: 9rem 0 0 0 ;
    }

    @media (max-width: 1100px) {
      padding: 6rem 4rem 0rem;
      text-align: center;
      font-size: 16pt;
      flex: 0 1 40%;
    }

    @media (max-width: 480px) {
      padding: 6rem 4rem 1rem;
    }
  }
}
</style>
