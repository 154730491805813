<template>
  <div class="project-container" v-for="project in projects" :key="project.id">
    <div class="project-card">
      <div class="project-id">
        #<span class="id-numb">{{ project.id }}</span>
      </div>
      <div class="project-card-left">
        <h4>{{ project.usedSkills }}</h4>
      </div>
      <div class="project-card-middle">
        <figure>
          <img :src="project.image" alt="project image" />
          <figcaption>
            <img src="../../../assets/images/soldier-avatar.jpg" alt="on hover avatar image" />
          </figcaption>
        </figure>
      </div>
      <div class="project-card-right">
        <h2>{{ project.name }}</h2>
        <a href="#">VISIT THE WEBSITE
          <hr />
        </a>
        <div class="project-social-icons">
          <a href="https://gitlab.com/MikicB" class="project-social-icons-link" target="_blank">
            <i class="fa-brands fa-square-gitlab"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import image1 from '../../../assets/images/logo.jpg'
import image2 from '../../../assets/images/cool-background.jpg'
import image3 from '../../../assets/images/background-land-page.jpg'

export default {
  data() {
    return {
      projects: [
        {
          id: 1,
          name: "Project in progress 1",
          usedSkills: "HTML, CSS, JS, BOOTSTRAP, PHP",
          image: image1
        },
        {
          id: 2,
          name: "Project in progress 2",
          usedSkills: "HTML, CSS, JS, BOOTSTRAP, VUEJS",
          image: image2
        },
        {
          id: 3,
          name: "Skola kosarke Prvi Kos",
          usedSkills: "HTML, CSS, JS, BOOTSTRAP, PHP",
          image: image3
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.project-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem;

  @media (max-width: 480px) {
    padding: 3rem 1rem 3rem;
  }
  @media (max-width: 280px) {
    padding: 2rem 1rem;
  }

  .project-card {
    width: 40%;
    display: flex;
    text-align: center;
    align-items: center;
    border: 1px solid #e0a13437;
    background: linear-gradient(180deg,
        rgba(35, 37, 49, 0.5),
        rgba(15, 15, 24, 0.5));
    backdrop-filter: blur(70px);
    padding: 3rem 0rem;
    border-radius: 50px;
    justify-content: space-evenly;
    position: relative;

    @media (min-width: 1401px) {
      width: 100%;
    }

    @media (max-width: 1400px) {
      width: 60%;
      padding: 2rem;
    }

    @media (min-width: 1399px) {
      width: 40%;
    }

    @media (max-width: 1100px) {
      width: 50%;
      flex-direction: column;
      padding: 1.5rem;
      border-radius: 30px;
    }

    @media (max-width: 767px) {
      width: 100%;
      flex-direction: column;
      padding: 1.5rem;
      border-radius: 30px;
    }

    @media (max-width: 480px) {
      width: 80%;
    }

    @media (max-width: 280px) {
      width: 100%;
    }

    .project-id {
      color: #e0a134;
      font-size: 80pt;
      font-weight: 600;
      position: absolute;
      top: -65px;
      opacity: 0.1;
      right: -40px;

      @media (max-width: 1400px) {
        font-size: 70pt;
        top: -55px;
      }

      @media (max-width: 1100px) {
        font-size: 50pt;
        display: block;
        box-sizing: border-box;
        margin: 1rem;
      }

      @media (max-width: 280px) {
        font-size: 40pt;
        right: -20px;
        ;
      }
    }

    .project-card-left {
      h4 {
        writing-mode: tb-rl;
        font-size: 0.7rem;
        letter-spacing: 0.17rem;
        color: #e0a134;
        transform: rotate(180deg);
        opacity: 0.7rem;
        cursor: pointer;
        transition: color 1s ease-out;

        &:hover {
          transition: color 0.3s ease-in-out;
          color: #e0a13498;
        }

        @media (max-width: 1400px) {
          margin-left: -50px;
          font-size: 0.5rem;
        }

        @media (max-width: 1100px) {
          display: none;
        }
      }
    }

    .project-card-middle {
      width: 40%;
      height: 22.5rem;
      position: relative;
      border-radius: 50px;
      overflow: hidden;
      background-size: cover;
      cursor: pointer;

      figure {
        font-family: "Raleway", Arial, sans-serif;
        position: relative;
        overflow: hidden;
        width: 100%;
        color: #000000;
        text-align: center;
        -webkit-perspective: 50em;
        perspective: 50em;
        height: 100%;
      }

      figure * {
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
      }

      figure img {
        max-width: 100%;
        vertical-align: top;
        position: relative;
      }

      figure figcaption {
        top: 50%;
        left: 20px;
        right: 20px;
        position: absolute;
        opacity: 0;
        z-index: 1;
      }

      figure:after {
        background-color: black;
        position: absolute;
        content: "";
        display: block;
        top: 20px;
        left: 20px;
        right: 20px;
        bottom: 20px;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
        -webkit-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
        -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        opacity: 0;
        border-radius: 50px;
      }

      figure:hover figcaption {
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        opacity: 1;
        -webkit-transition-delay: 0.2s;
        transition-delay: 0.2s;
      }

      figure:hover:after {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 0.9;
      }

      figcaption {
        img {
          width: 50%;
          border-radius: 50%;
        }
      }

      @media (max-width: 1400px) {
        width: 40%;
        height: 20rem;
      }

      @media (max-width: 1100px) {
        width: 100%;
        height: 20rem;
      }

      @media (max-width: 480px) {
        height: 14rem;
      }

      @media (max-width: 280px) {
        height: 13rem;
      }

      img {
        position: absolute;
        background-position: cover;
        width: 100%;
        height: 100%;
      }
    }

    .project-card-right {
      width: 30%;
      flex: 0 1 30%;
      display: flex;
      flex-direction: column;
      height: 20rem;
      text-align: left;
      padding: 0rem;
      justify-content: space-evenly;

      @media (max-width: 1100px) {
        width: 100%;
        flex: 0 1 40%;
      }

      h2 {
        font-size: 2rem;
        letter-spacing: -0.1rem;
        color: var(--light);

        @media (max-width: 1100px) {
          margin-top: 0.5rem;
          font-size: 1.8rem;
        }

        @media (max-width: 480px) {
          margin-top: 1rem;
          font-size: 1.4rem;
        }

        @media (max-width: 280px) {
          margin-top: 2rem;
          font-size: 1.3rem;
        }
      }

      a {
        width: fit-content;
        color: #e0a134;
        text-decoration: none;
        font-size: 10pt;
        display: inline-block;
        position: relative;
        &:hover {
          color: #e0a13498;
        }

        @media (max-width: 1100px) {
          margin-top: 0.5rem;
        }

        @media (max-width: 480px) {
          font-size: 8pt;
        }

        @media (max-width: 280px) {
          font-size: 8pt;
        }

        hr {
          width: 18%;
          margin-top: 0.4rem;
          transition: width 1s ease;
          opacity: 0.7;
        }

        &:hover {
          hr {
            width: 100%;
            color: #e0a134;
            opacity: 1;

            // @media (max-width: 1400px) {
            //   width: 78%;
            // }

            // @media (max-width: 480px) {
            //   width: 64%;
            // }

            // @media (max-width: 280px) {
            //   width: 64%;
            // }
          }
        }

        i {
          color: #e0a1348e;
          font-size: 2rem;
          opacity: 0.7;
          transition: transform 1s ease;

          &:hover {
            transform: scale(1.2);
            transition: transform 1s ease;
            color: #e0a134;
          }
        }
      }

      .project-social-icons {
        width: fit-content;
        .project-social-icons-link {
          width: fit-content;
        }
      }
    }
  }
}
</style>
