<template>
  <footer> <small>&copy; Copyright {{ currentYear }}, Mikic Benjamin </small> </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  }
}
</script>

<style lang="scss" scoped>
footer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: .5rem;
  background-color: rgba(0, 0, 0, .9);

  small {
    color: rgba(255, 255, 255, 0.737);
    letter-spacing: .5px;
  }
}
</style>