<template>
  <h2>other tech<span class="dash-color">'</span>s</h2>
  <div class="main-other-skill-container">
    <div class="other-skill-container">
      <progress-bar :percentage="40" :label="`VueJS`" :color="'#41b883'"></progress-bar>
      <progress-bar :percentage="20" :label="`NodeJs`" :color="'#3C873a'"></progress-bar>
      <progress-bar :percentage="70" :label="`Bootstrap`" :color="'#563d7c'"></progress-bar>
      <progress-bar :percentage="50" :label="`Git`" :color="'#FC6D27'"></progress-bar>
      <progress-bar :percentage="30" :label="`Npm`" :color="'#8C929D'"></progress-bar>
    </div>
  </div>
</template>

<script>
import ProgressBar from './ProgressBar.vue';

export default {
  components: {
    ProgressBar,
  },
}
</script>

<style scoped lang="scss">
$vue: #41b883;
$node: #3C873a;
$npm: #CC3534;
$git: #8C929D;
$bootstrap: #553C7B;

h2 {
  color: var(--light);
  padding: 2rem 2rem 0;
  text-align: center;
  font-size: 24pt;
  font-weight: 900;
  letter-spacing: 1px;
  
  .dash-color {
    color: var(--nameColor)
  }
  
  @media (max-width: 1100px) {
    font-size: 16pt;
  }
}

.main-other-skill-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 1400px) {
    flex-direction: column;
  }
  
  .other-skill-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 100%;
    padding: 4rem;

    @media (max-width: 1400px) {
      width: 70%;
      padding: 0 1rem 2rem 1rem;
    }

    @media (max-width: 1100px) {
      width: 70%;
      padding-bottom: 1rem;
      padding: 0rem;
    }

    @media (max-width: 768px) {
      width: 90%;
    }

    @media (max-width: 480px) {
      width: 90vw;
    }

    @media (max-width: 280px) {
      width: 100vw;
    }

  }
}
</style>
